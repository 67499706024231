import { Link } from "gatsby";
import React from "react";
import { MAILCHIMP_ENDPOINT, YEAR } from "../constants";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import tw, { styled } from "twin.macro";
import PaddedContainer from "./PaddedContainer";

import Strava from "../assets/social-strava.svg";
import Instagram from "../assets/social-instagram.svg";
import Twitter from "../assets/social-twitter.svg";
import Logo from "../assets/logo.svg";

const SocialLink = styled(OutboundLink)`
  ${tw`inline-block fill-current text-gray-300 hover:text-gray-400 not-last:mr-8`}

  svg {
    ${tw`w-6 h-6`}
  }
`;

const Footer = () => {
  return (
    <>
      <div tw="bg-gray-800 text-gray-300">
        <PaddedContainer tw="py-4">
          <div tw="font-bold text-lg">Join the club</div>
          <div tw="text-sm mb-4">
            Get club updates, new routes and merch delivered to your inbox.
          </div>
          <form
            action={MAILCHIMP_ENDPOINT}
            method="post"
            name="mc-embedded-subscribe-form"
            target="_blank"
            noValidate
          >
            <div tw="bg-white rounded p-1 pl-3 flex mb-4 text-xs max-w-full w-full sm:w-sm">
              <input
                tw="flex-grow text-gray-700"
                type="email"
                name="EMAIL"
                placeholder="Email"
                required
              />
              <input
                tw="bg-brand-500 rounded text-white uppercase font-bold py-2 px-4 cursor-pointer"
                type="submit"
                value="Subscribe"
                name="subscribe"
              />
            </div>
            <div tw="absolute" style={{ left: "-5000px" }} aria-hidden="true">
              <input
                type="text"
                name="b_e8430efd4e06a5e7e0a8df852_42dc1162ab"
                tabIndex="-1"
              />
            </div>
          </form>
          <div tw="pt-2">
            <SocialLink href="https://www.strava.com/clubs/677850/">
              <Strava aria-label="Strava" />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/routes.cc/">
              <Instagram aria-label="Instagram" />
            </SocialLink>
            <SocialLink href="https://twitter.com/routes_cc/">
              <Twitter aria-label="Twitter" />
            </SocialLink>
          </div>
        </PaddedContainer>
      </div>
      <div tw="bg-gray-900">
        <PaddedContainer tw="text-gray-700 text-xs py-8">
          <div tw="flex items-center justify-between mb-8">
            <div tw="text-gray-500 text-sm font-bold">
              <a href="mailto:hello@routes.cc" tw="block mb-2">
                Contact us
              </a>
              <Link to="/about" tw="block mb-2">
                About
              </Link>
            </div>
            <div tw="text-gray-200 text-right">
              Want to recommend a route?
              <br />
              <a href="mailto:hello@routes.cc" tw="text-brand-500">
                hello@routes.cc
              </a>
            </div>
          </div>
          <div tw="block">
            <Link to="/">
              <Logo
                tw="fill-current my-2 h-8 w-auto"
                role="img"
                aria-label="routes.cc"
              />
            </Link>
            © {YEAR}
          </div>
        </PaddedContainer>
      </div>
    </>
  );
};

export default Footer;
