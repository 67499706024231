import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
          }
        }
      }
    `
  );

  const { pathname } = useLocation();

  return (
    <Helmet
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
      htmlAttributes={{
        lang: "en"
      }}
    >
      <meta property="og:title" content={title || site.siteMetadata.title} />
      <meta
        property="og:description"
        content={description || site.siteMetadata.description}
      />
      <meta
        property="og:image"
        content={site.siteMetadata.siteUrl + "/images/social.png"}
      />
      <meta property="og:url" content={site.siteMetadata.siteUrl + pathname} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      {process.env.GATSBY_DEPLOY_ENV !== "prod" && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
  );
};

export default SEO;
