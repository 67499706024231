import React from "react";
import { createGlobalStyle } from "styled-components";
import tw, { GlobalStyles as BaseStyles } from "twin.macro";

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/700.css";

const CustomStyles = createGlobalStyle`
  body {
    ${tw`text-gray-900`}
  }

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    ${tw`h-full`}
  }

  #gatsby-focus-wrapper {
    ${tw`flex flex-col`}
  }
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export { GlobalStyles };
