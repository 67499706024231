import { Link } from "gatsby";
import React from "react";
import tw from "twin.macro";
import PaddedContainer from "./PaddedContainer";

import Logo from "../assets/logo.svg";

const StyledHeader = tw.header`bg-brand-500 shadow`;

const Content = tw(PaddedContainer)`py-2 flex items-center justify-between`;

const StyledLogo = tw(Logo)`text-white fill-current my-2 h-4 w-auto`;

const NavLink = tw(Link)`text-white hover:text-brand-100`;

const Header = () => (
  <StyledHeader>
    <Content>
      <Link to="/">
        <StyledLogo role="img" aria-label="routes.cc" />
      </Link>
      <NavLink to="/about/">About</NavLink>
    </Content>
  </StyledHeader>
);

export default Header;
