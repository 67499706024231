import tw, { styled } from "twin.macro";

const PaddedContainer = styled.div`
  ${tw`w-full mx-auto`}
  max-width: 800px;
  padding-left: max(0.5rem, env(safe-area-inset-left));
  padding-right: max(0.5rem, env(safe-area-inset-right));
`;

export default PaddedContainer;
