import React from "react";
import tw from "twin.macro";

import Header from "./Header";
import Footer from "./Footer";
import { GlobalStyles } from "./GlobalStyles";

const Content = tw.div`flex-grow flex-shrink-0 flex flex-col`;

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Header />
    <Content>{children}</Content>
    <Footer />
  </>
);

export default Layout;
